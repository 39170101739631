"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeSinceRecordingStartMs = exports.getRecordingDurationMs = void 0;
function getRecordingDurationMs(windows) {
    if (!windows.length)
        return 0;
    let duration = 0;
    for (const [start, end] of windows)
        duration += end - start;
    return duration;
}
exports.getRecordingDurationMs = getRecordingDurationMs;
function getTimeSinceRecordingStartMs(timestamp, // Date timestamp
windows) {
    let timeSince = 0;
    for (const [start, end] of windows) {
        // This should never happen, but if timestamp is before all windows, return
        // the start of the earliest window.
        if (timestamp < start)
            return start;
        // If the timestamp is in this window, add the last chunk of time between
        // this timestamp and the start of the window, and return our accrued time.
        if (timestamp <= end) {
            timeSince += timestamp - start;
            return timeSince;
        }
        // Otherwise, add the duration of the current window to our accrued time
        // and move onto the next window
        timeSince += end - start;
    }
    // This should also never happen but if we never found a window that the
    //timestamp was in, just return the end of the last window.
    return windows[windows.length - 1][1];
}
exports.getTimeSinceRecordingStartMs = getTimeSinceRecordingStartMs;
