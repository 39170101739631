"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scaleForVideo = exports.scaleWithConstraint = void 0;
// For our current exports, we cap the length of the longest edge, so we need
// retain the aspect ratio and scale the width and height accordingly if the
// longest edge exceeds the size constraint.
function scaleWithConstraint({ width, height, }, sizeConstraint) {
    if (width <= sizeConstraint && height <= sizeConstraint) {
        return { width, height };
    }
    if (width > height) {
        return {
            width: sizeConstraint,
            height: Math.round((height * sizeConstraint) / width),
        };
    }
    return {
        width: Math.round((width * sizeConstraint) / height),
        height: sizeConstraint,
    };
}
exports.scaleWithConstraint = scaleWithConstraint;
// H264 requires even dimensions
function scaleForVideo({ width, height }, sizeConstraint) {
    let { width: scaledWidth, height: scaledHeight } = scaleWithConstraint({ width, height }, sizeConstraint);
    if (scaledWidth % 2 !== 0) {
        scaledWidth += 1;
    }
    if (scaledHeight % 2 !== 0) {
        scaledHeight += 1;
    }
    return { width: scaledWidth, height: scaledHeight };
}
exports.scaleForVideo = scaleForVideo;
