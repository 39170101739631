import { Feature } from '@arcadehq/shared/types'
import { Flow, Team, TeamTheme } from 'src/types'

import { AccountCore, AccountProtectedFields } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export type Watermark = ReturnType<typeof watermark>

export const watermark = (
  core: AccountCore,
  { plan, userProfile }: AccountProtectedFields,
  entity: TeamTheme | Team | null
) => ({
  get mayToggle(): boolean {
    return !plan.startsWith('Free')
  },

  showInEditor(flow?: Flow) {
    if (!flow) return true

    // TODO materialize entitlements on the base class
    const isShowArcadeButtonNullOrUndefined =
      flow.showArcadeButton === null || flow.showArcadeButton === undefined
    switch (plan) {
      case 'Free':
      case 'Free (Growth Churned)':
      case 'Free (Growth Trial Ended)':
      case 'Free (Pro Churned)':
      case 'Free (Pro Churned) (Growth Churned)':
      case 'Free (Pro Churned) (Growth Trial Ended)':
        return true
      case 'Pro':
      case 'Pro (Growth Churned)':
      case 'Pro (Growth Trial Ended)':
        return isShowArcadeButtonNullOrUndefined
          ? !userProfile.features?.[Feature.NoArcadeBranding]
          : flow.showArcadeButton !== false
      case 'Growth':
      case 'Growth Trial':
        if (isShowArcadeButtonNullOrUndefined && flow.belongsToTeam && entity) {
          return !entity.features?.[Feature.NoArcadeBranding]
        }
        return flow.showArcadeButton !== false
    }
  },

  showPaywallInEditor(flow: Flow) {
    switch (plan) {
      case 'Free':
      case 'Free (Growth Churned)':
      case 'Free (Growth Trial Ended)':
      case 'Free (Pro Churned)':
      case 'Free (Pro Churned) (Growth Churned)':
      case 'Free (Pro Churned) (Growth Trial Ended)':
        return true
      case 'Pro':
      case 'Pro (Growth Churned)':
      case 'Pro (Growth Trial Ended)':
        return true
      case 'Growth':
      case 'Growth Trial':
        return flow.belongsToTeam
    }
  },

  get showByDefault() {
    if (entity) {
      return !getSubfield(entity, 'features', Feature.NoArcadeBranding)
    }
    // TODO do Pro users have a default?
    return true
  },

  setShowByDefault(showByDefault: boolean) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'features',
        Feature.NoArcadeBranding,
        !showByDefault,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  get linkUrl() {
    return getSubfield(entity, 'watermark', 'link')
  },

  get imageUrl() {
    return getSubfield(entity, 'watermark', 'imageUrl')
  },

  get hoverText() {
    return getSubfield(entity, 'watermark', 'text')
  },

  setLinkUrl(linkUrl: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'watermark',
      'link',
      linkUrl,
      core.userId
    )
  },

  setImageUrl(imageUrl: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'watermark',
      'imageUrl',
      imageUrl,
      core.userId
    )
  },

  setHoverText(hoverText: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'watermark',
      'text',
      hoverText,
      core.userId
    )
  },
})
