import {
  defaultTeamPrefs,
  defaultUserPrefs,
} from '@arcadehq/shared/lib/helpers'
import { BackgroundImage, FlowWrapper } from '@arcadehq/shared/types'
import { Team, TeamTheme } from 'src/types'

import { AccountCore, AccountProtectedFields } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export type FlowDefaults = ReturnType<typeof flowDefaults>

export const flowDefaults = (
  core: AccountCore,
  _fields: AccountProtectedFields,
  entity: TeamTheme | Team | null
) => ({
  get font() {
    const value = getSubfield(entity, 'prefs', 'fontFamily')
    if (value !== undefined) {
      return value
    }

    // TODO check user profile
    return core.isActiveMemberOfTeam
      ? defaultTeamPrefs.fontFamily
      : defaultUserPrefs.fontFamily
  },

  get backgroundImage(): BackgroundImage | undefined {
    if (entity) {
      return getSubfield(entity, 'prefs', 'bgImage')
    }
  },

  get wrapper(): FlowWrapper | undefined {
    if (entity) {
      return getSubfield(entity, 'prefs', 'wrapper')
    }
  },

  get navBarEnabled() {
    if (entity) {
      return !!getSubfield(entity, 'prefs', 'showFlowNavIndicator')
    }
    return false
  },

  get startOverlayEnabled() {
    if (entity) {
      return !!getSubfield(entity, 'prefs', 'showStartOverlay')
    }
    return false
  },

  get startOverlayText() {
    if (entity) {
      return getSubfield(entity, 'prefs', 'startOverlayButtonText')
    }
  },

  setFont(font: string | undefined) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'fontFamily',
        font,
        core.userId
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },

  setBackgroundImage(backgroundImage: BackgroundImage | undefined) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'bgImage',
        backgroundImage,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setWrapper(wrapper: FlowWrapper | undefined) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'wrapper',
        wrapper,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setNavBarEnabled(navBarEnabled: boolean) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'showFlowNavIndicator',
        navBarEnabled,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setStartOverlayEnabled(startOverlayEnabled: boolean) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'showStartOverlay',
        startOverlayEnabled,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setStartOverlayText(startOverlayText?: string) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'startOverlayButtonText',
        startOverlayText,
        core.userId
      )
    }
    return Promise.resolve(false)
  },
})
