import { defaultTeamPrefs, defaultUserPrefs } from '@arcadehq/shared/helpers'
import { Team, TeamTheme } from 'src/types'

import { AccountCore, AccountProtectedFields } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

// TODO do something to bake in the global default values here

export type HotspotDefaults = ReturnType<typeof hotspotDefaults>

export const hotspotDefaults = (
  core: AccountCore,
  { userProfile, team }: AccountProtectedFields,
  entity: TeamTheme | Team | null
) => ({
  get backgroundColor() {
    if (team?.currentSubscriber) {
      return getSubfield(entity, 'prefs', 'bgColor') ?? defaultTeamPrefs.bgColor
    }
    if (userProfile) {
      return (
        getSubfield(userProfile, 'prefs', 'bgColor') ?? defaultUserPrefs.bgColor
      )
    }

    return defaultUserPrefs.bgColor
  },

  get textColor() {
    if (team?.currentSubscriber) {
      return getSubfield(entity, 'prefs', 'fgColor') ?? defaultTeamPrefs.fgColor
    }
    if (userProfile) {
      return (
        getSubfield(userProfile, 'prefs', 'textColor') ??
        defaultUserPrefs.textColor
      )
    }

    return defaultUserPrefs.textColor
  },

  setTeamBackgroundColor(backgroundColor: string | undefined) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'bgColor',
        backgroundColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setUserBackgroundColor(backgroundColor: string | undefined) {
    if (userProfile) {
      return setOrDeleteSubfield(
        userProfile,
        'prefs',
        'bgColor',
        backgroundColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setTeamTextColor(textColor: string | undefined) {
    if (entity) {
      return setOrDeleteSubfield(
        entity,
        'prefs',
        'fgColor',
        textColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setUserTextColor(textColor: string | undefined) {
    if (userProfile) {
      return setOrDeleteSubfield(
        userProfile,
        'prefs',
        'textColor',
        textColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setBothColors(backgroundColor: string, textColor: string) {
    if (entity) {
      const prefs = {
        ...(entity.prefs ?? {}),
        bgColor: backgroundColor,
        textColor,
      }

      return entity.update(
        {
          prefs,
        },
        core.userId
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },
})
