import { AccountMixin } from '../AccountCore'

export interface Insights {
  timezone: string
  clearbitEnabled: boolean
}

export const insights: AccountMixin<Insights> = (_core, { team }) => ({
  get timezone() {
    return team?.timezone ?? 'America/Los_Angeles'
  },

  get clearbitEnabled() {
    return team?.clearbitEnabled ?? false
  },
})
