"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThumbnailUrlFromVideoStep = exports.getThumbnailUrlFromVideoStreamUrl = void 0;
function getThumbnailUrlFromVideoStreamUrl(streamUrl, time = 0) {
    return (streamUrl
        ?.replace('stream', 'image')
        .replace('.m3u8', `/thumbnail.png?time=${time}`) ?? '');
}
exports.getThumbnailUrlFromVideoStreamUrl = getThumbnailUrlFromVideoStreamUrl;
function getThumbnailUrlFromVideoStep(step, force = false) {
    if (step.videoThumbnailUrl && !force)
        return step.videoThumbnailUrl;
    return getThumbnailUrlFromVideoStreamUrl(step.url, step.startTimeFrac && step.duration ? step.startTimeFrac * step.duration : 0);
}
exports.getThumbnailUrlFromVideoStep = getThumbnailUrlFromVideoStep;
