"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWatermarkDisplayProps = void 0;
const types_1 = require("../types");
const getWatermarkDisplayProps = (flow, flowCreatorUserProfile = null, flowTeam = null, theme) => {
    const { features, watermark } = theme ?? {};
    if (flowCreatorUserProfile === null && flowTeam === null) {
        return {
            shouldDisplay: true,
            customProps: undefined,
        };
    }
    if (flowTeam?.currentSubscriber) {
        // Team exists and is allowed to remove/change watermarks
        if (flow.belongsToTeam) {
            // Flow is in "Team" folder
            if (features?.[types_1.Feature.NoArcadeBranding]) {
                // Team has turned off the watermark globally
                return {
                    shouldDisplay: false,
                };
            }
            else {
                // Team has not turned off the watermark globally,
                // and may have supplied custom props
                return {
                    shouldDisplay: true,
                    customProps: watermark,
                };
            }
        }
        else {
            // Flow is in "My Arcades" folder
            if (flow.showArcadeButton === null) {
                // Flow-level watermark setting has not been set
                if (flowCreatorUserProfile?.activeTeamId === flowTeam.id) {
                    // User is an active member of the team
                    if (features?.[types_1.Feature.NoArcadeBranding]) {
                        // Team has turned off the watermark globally
                        return {
                            shouldDisplay: false,
                        };
                    }
                    else {
                        // Team has not turned off the watermark globally,
                        // and may have supplied custom props
                        return {
                            shouldDisplay: true,
                            customProps: watermark,
                        };
                    }
                }
                else {
                    // User is not an active member of the team
                    return {
                        shouldDisplay: true,
                    };
                }
            }
            else if (flow.showArcadeButton === true) {
                // Flow-level watermark is explicitly enabled (TODO: how?)
                return {
                    shouldDisplay: true,
                    customProps: watermark,
                };
            }
            else {
                // Flow-level watermark is explicitly disabled (TODO: how?)
                return {
                    shouldDisplay: false,
                };
            }
        }
    }
    // Team doesn't exist or isn't allowed to remove/change watermarks
    if (flowCreatorUserProfile?.currentSubscriber) {
        // Creator is a Pro user
        if (flow.showArcadeButton === null) {
            // Flow-level watermark setting has not been set
            if (flowCreatorUserProfile.features?.[types_1.Feature.NoArcadeBranding]) {
                // Pro user has turned off the watermark globally (TODO: how?)
                return {
                    shouldDisplay: false,
                };
            }
            else {
                // Pro user has not turned off the watermark globally
                return {
                    shouldDisplay: true,
                    customProps: undefined,
                };
            }
        }
        else if (flow.showArcadeButton === true) {
            // Flow-level watermark is explicitly enabled
            return {
                shouldDisplay: true,
                customProps: undefined,
            };
        }
        else {
            // Flow-level watermark is explicitly disabled
            return {
                shouldDisplay: false,
            };
        }
    }
    // Creator is a Free user
    return {
        shouldDisplay: true,
        customProps: undefined,
    };
};
exports.getWatermarkDisplayProps = getWatermarkDisplayProps;
