import { UserProfile } from 'src/types'

import { AccountMixin } from '../AccountCore'

export interface Onboarding {
  useCases: UserProfile['onboardingUseCases'] | undefined
  getStartedArticlesHidden: UserProfile['getStartedArticlesHidden'] | undefined

  saveFormOutput: (
    useCases: UserProfile['onboardingUseCases'],
    companySize: UserProfile['onboardingCompanySize']
  ) => Promise<boolean>
  hideGetStartedArticle: (articleId: string) => Promise<boolean>
}

export const onboarding: AccountMixin<Onboarding> = (
  core,
  { userProfile }
) => ({
  get useCases() {
    return userProfile.onboardingUseCases
  },

  get getStartedArticlesHidden() {
    return userProfile.getStartedArticlesHidden
  },

  saveFormOutput(useCases, companySize) {
    return userProfile.update(
      {
        onboardingUseCases: useCases,
        onboardingCompanySize: companySize,
      },
      core.userId
    )
  },

  hideGetStartedArticle(articleId) {
    return userProfile.update(
      {
        getStartedArticlesHidden: {
          ...userProfile.getStartedArticlesHidden,
          [articleId]: true,
        },
      },
      core.userId
    )
  },
})
