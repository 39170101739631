import { Team, TeamTheme } from 'src/types'

import { AccountCore, AccountProtectedFields } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export type SharePage = ReturnType<typeof sharePage>

export const sharePage = (
  core: AccountCore,
  _fields: AccountProtectedFields,
  entity: TeamTheme | Team | null
) => ({
  get logoLinkUrl() {
    return getSubfield(entity, 'sharePageLogo', 'link')
  },

  get logoImageUrl() {
    return getSubfield(entity, 'sharePageLogo', 'imageUrl')
  },

  get ctaButtonText() {
    return getSubfield(entity, 'sharePageButton', 'text')
  },

  get ctaButtonColor() {
    return getSubfield(entity, 'sharePageButton', 'bgColor')
  },

  get ctaButtonTextColor() {
    return getSubfield(entity, 'sharePageButton', 'textColor')
  },

  get ctaButtonLinkUrl() {
    return getSubfield(entity, 'sharePageButton', 'link')
  },

  setLogoLinkUrl(logoLinkUrl: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'sharePageLogo',
      'link',
      logoLinkUrl,
      core.userId
    )
  },

  setLogoImageUrl(logoImageUrl: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'sharePageLogo',
      'imageUrl',
      logoImageUrl,
      core.userId
    )
  },

  setCtaButtonText(ctaButtonText: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'sharePageButton',
      'text',
      ctaButtonText,
      core.userId
    )
  },

  setCtaButtonColor(ctaButtonColor: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'sharePageButton',
      'bgColor',
      ctaButtonColor,
      core.userId
    )
  },

  setCtaButtonTextColor(ctaButtonTextColor: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'sharePageButton',
      'textColor',
      ctaButtonTextColor,
      core.userId
    )
  },

  setCtaButtonLinkUrl(ctaButtonLinkUrl: string | undefined) {
    return setOrDeleteSubfield(
      entity,
      'sharePageButton',
      'link',
      ctaButtonLinkUrl,
      core.userId
    )
  },
})
