import { AuthUser } from 'next-firebase-auth'
import { Team, UserProfile } from 'src/types'

import { AccountCore, AccountProtectedFields } from './AccountCore'
import { Extension, extension } from './features/extension'
import { FlowDefaults, flowDefaults } from './features/flow-defaults'
import { Flows, flows } from './features/flows'
import { HotspotDefaults, hotspotDefaults } from './features/hotspot-defaults'
import { Insights, insights } from './features/insights'
import { Intercom, intercom } from './features/intercom'
import { Onboarding, onboarding } from './features/onboarding'
import { Privacy, privacy } from './features/privacy'
import { Publishing, publishing } from './features/publishing'
import { SharePage, sharePage } from './features/share-page'
import { Watermark, watermark } from './features/watermark'
import { Workspace, workspace } from './features/workspace'

export class Account extends AccountCore {
  public extension: Extension
  public flowDefaults: FlowDefaults
  public flows: Flows
  public hotspotDefaults: HotspotDefaults
  public insights: Insights
  public intercom: Intercom
  public onboarding: Onboarding
  public privacy: Privacy
  public publishing: Publishing
  public sharePage: SharePage
  public watermark: Watermark
  public workspace: Workspace

  public getProtectedFields(): AccountProtectedFields {
    return {
      authUser: this.authUser,
      userProfile: this.userProfile,
      team: this.team,
      plan: this.plan,
    }
  }

  constructor(authUser: AuthUser, userProfile: UserProfile, team: Team | null) {
    super(authUser, userProfile, team)

    // TODO this.appearance
    // TODO this.trial
    const fields = this.getProtectedFields()

    this.extension = extension(this, fields)
    this.flowDefaults = flowDefaults(this, fields, fields.team)
    this.hotspotDefaults = hotspotDefaults(this, fields, fields.team)
    this.insights = insights(this, fields)
    this.intercom = intercom(this, fields)
    this.onboarding = onboarding(this, fields)
    this.privacy = privacy(this, fields)
    this.publishing = publishing(this, fields)
    this.sharePage = sharePage(this, fields, fields.team)
    this.watermark = watermark(this, fields, fields.team)
    this.workspace = workspace(this, fields)

    this.flows = flows(this, fields, this.workspace)
  }
}
