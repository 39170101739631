"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportStatus = exports.ExportType = void 0;
var ExportType;
(function (ExportType) {
    ExportType["mp4"] = "mp4";
    ExportType["gif"] = "gif";
})(ExportType || (exports.ExportType = ExportType = {}));
var ExportStatus;
(function (ExportStatus) {
    ExportStatus["Pending"] = "pending";
    ExportStatus["Generating"] = "generating";
    ExportStatus["Succeeded"] = "succeeded";
    ExportStatus["Failed"] = "failed";
})(ExportStatus || (exports.ExportStatus = ExportStatus = {}));
